import React from 'react';
import { shape, string, bool } from 'prop-types';

import classNames from 'classnames';
import { RecommendationsCardVerticalTitleFirst } from '@recommendations-fe/card';

import IconFactory from '../../icons/recommendations-fe/icon-factory';
import { removeCents } from '../utils/format-items';

const ItemBestSeller = ({ item, namespace, preload }) => (
  <RecommendationsCardVerticalTitleFirst
    {...removeCents(item)}
    key={item.id}
    className={classNames(`${namespace}__item`)}
    IconFactory={IconFactory}
    preload={preload}
  />
);

ItemBestSeller.propTypes = {
  item: shape({}).isRequired,
  namespace: string,
  preload: bool,
};

ItemBestSeller.defaultProps = {
  namespace: '',
};

export default ItemBestSeller;

import React from 'react';

import { loadable } from 'nordic/lazy';

import useExhibitorHeader from './use-exhibitor-pi';

const ChunkExhibitor = () => {
  const exhibitorProps = useExhibitorHeader();
  const ExhibitorHeader = loadable(async () =>
    import(/* webpackChunkName: "chunk-header-exhibitor-pi" */ './exhibitor-header.desktop.pi'),
  );

  return exhibitorProps ? <ExhibitorHeader {...exhibitorProps} /> : null;
};

export default ChunkExhibitor;

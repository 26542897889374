import React from 'react';

import classnames from 'classnames';

import { useSearch } from '../../hooks/context';
import SeoContents from '../seo-contents/seo-contents';

const namespace = 'ui-search-footer-components';
const SEO_BLOGS_INTERVENTION = 'SEO_BLOGS_INTERVENTION';
const SEO_BLOGS = 'SEO_BLOGS';

const SeoBlogsComponent = () => {
  const { results } = useSearch();

  let seoBlogsInterventionObject = null;

  const seoBlogs = results?.find((result) => result.id === SEO_BLOGS);

  if (seoBlogs) {
    seoBlogsInterventionObject = seoBlogs;
  } else {
    seoBlogsInterventionObject = results?.find((result) => result.id === SEO_BLOGS_INTERVENTION);
  }

  if (!seoBlogsInterventionObject) {
    return null;
  }

  return (
    <div className={classnames(namespace)}>
      <div className={classnames(`${namespace}__wrapper`)}>
        <div className={classnames(`${namespace}__container`)}>
          <SeoContents items={[seoBlogsInterventionObject]} />
        </div>
      </div>
    </div>
  );
};

export default SeoBlogsComponent;

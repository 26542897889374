import React from 'react';

import { loadable } from 'nordic/lazy';

import { useSearch } from '../../hooks/context';
import { useUser } from '../context/user';
import { useStaticProps } from '../context/static-props';
import ItemSearchGrid from '../grid-layout/cards/item-search.desktop';
import ItemBestSeller from '../grid-layout/cards/item-best-seller.desktop';

const HIGHER_GROWTH_TREND = 'HIGHER_GROWTH';
const MOST_WANTED_TREND = 'MOST_WANTED';
const MOST_POPULAR_TREND = 'MOST_POPULAR';
const MANUAL_TRENDS = 'MANUAL_TRENDS';
const CONSOLIDATED_TRENDS = 'CONSOLIDATED_TRENDS';
const OTHER_FILLED_TRENDS = 'OTHER_FILLED_TRENDS';
const BEST_SELLERS_ITEMS = 'BEST_SELLER';
const BEST_SELLERS_POLYCARDS = 'BEST_SELLER_POLYCARDS';
const RECOMMENDED_ITEMS = 'RECOMMENDED_ITEMS';
const RECOMMENDED_ITEMS_POLYCARDS = 'RECOMMENDED_ITEMS_POLYCARDS';

const renderDesktopContent = (props, hasCarousel, preload, deviceType, countryConfig) => {
  if (!props) {
    return null;
  }

  const { id, trends_categories: trends } = props;
  const trends_categories = trends ? JSON.parse(JSON.stringify(trends)) : trends;

  const CategoryTrendCarousel = loadable(async () =>
    import(/* webpackChunkName: "chunk-category-trend-carousel" */ './category-trend-carousel'),
  );
  const CategoryTrendContainer = loadable(async () =>
    import(
      /* webpackChunkName: "chunk-category-trend-container" */ '../category-trend-container/category-trend-container'
    ),
  );
  const ContainerItems = loadable(async () =>
    import(/* webpackChunkName: "chunk-container-items" */ '../grid-layout/container-items.desktop'),
  );

  const Polycard = loadable(
    async () => import(/* webpackChunkName: "chunk-best-sellers-polycard" */ '../grid-layout/cards/item-polycard'),
    {
      resolveComponent: (mod) => mod.Card,
    },
  );

  const PolycardContext = loadable(
    async () => import(/* webpackChunkName: "chunk-best-sellers-context" */ '../grid-layout/cards/item-polycard'),
    {
      resolveComponent: (mod) => mod.Context,
    },
  );

  switch (id) {
    case HIGHER_GROWTH_TREND:
    case MOST_WANTED_TREND:
    case MOST_POPULAR_TREND:
    case MANUAL_TRENDS:
      return <CategoryTrendCarousel key={`${id}-trend`} trends_categories={trends_categories} />;
    case CONSOLIDATED_TRENDS:
      return <CategoryTrendContainer key={`${id}-trend`} trends_categories={trends_categories} />;
    case OTHER_FILLED_TRENDS:
      return (
        <CategoryTrendContainer key={`${id}-trend`} trends_categories={trends_categories} hasCarousel={hasCarousel} />
      );
    case BEST_SELLERS_ITEMS:
      return (
        <ContainerItems
          key={`${id}-trend`}
          component={trends_categories}
          itemComponent={ItemBestSeller}
          preload={preload}
        />
      );
    case BEST_SELLERS_POLYCARDS:
    case RECOMMENDED_ITEMS_POLYCARDS:
      return (
        <ContainerItems
          key={`${id}-trend`}
          component={trends_categories}
          itemComponent={Polycard}
          itemContext={PolycardContext}
          preload={preload}
          deviceType={deviceType}
          countryConfig={countryConfig}
          preloadNumImage={3}
        />
      );
    case RECOMMENDED_ITEMS:
      return <ContainerItems key={`${id}-trend`} component={trends_categories} itemComponent={ItemSearchGrid} />;

    default:
      return null;
  }
};

const CategoryTrendCarouselCollection = () => {
  const { results } = useSearch();
  const { loggedIn } = useUser();
  const { deviceType, country } = useStaticProps();

  const mostWantedTrendsProps = results.find((x) => x.id === MOST_WANTED_TREND);
  const higherGrothTrendsProps = results.find((x) => x.id === HIGHER_GROWTH_TREND);
  const mostPopulaTrendsProps = results.find((x) => x.id === MOST_POPULAR_TREND);
  const manualTrendsProps = results.find((x) => x.id === MANUAL_TRENDS);
  const bestSellersItemsProps = results.find((x) => x.id === BEST_SELLERS_ITEMS || x.id === BEST_SELLERS_POLYCARDS);
  const recommendedItemsProps = results.find((x) => x.id === RECOMMENDED_ITEMS || x.id === RECOMMENDED_ITEMS_POLYCARDS);
  const consolidatedTrendsProps = results.find((x) => x.id === CONSOLIDATED_TRENDS);
  const otherFilledTrendsProps = results.find((x) => x.id === OTHER_FILLED_TRENDS);

  const hasCarousel = mostWantedTrendsProps || higherGrothTrendsProps || mostPopulaTrendsProps;
  const layoutItems = [
    renderDesktopContent(mostWantedTrendsProps),
    renderDesktopContent(higherGrothTrendsProps),
    renderDesktopContent(mostPopulaTrendsProps),
    renderDesktopContent(consolidatedTrendsProps),
    renderDesktopContent(manualTrendsProps),
  ];
  const dependantLayoutItems = [
    renderDesktopContent(bestSellersItemsProps, false, loggedIn, deviceType, country),
    renderDesktopContent(recommendedItemsProps, false, loggedIn, deviceType, country),
  ];
  const bottomLayoutItems = [renderDesktopContent(otherFilledTrendsProps, hasCarousel)];

  if (!loggedIn) {
    return [...layoutItems, ...dependantLayoutItems, ...bottomLayoutItems];
  }

  return [...dependantLayoutItems, ...layoutItems, ...bottomLayoutItems];
};

export default CategoryTrendCarouselCollection;

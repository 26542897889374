import React from 'react';
import PropTypes from 'prop-types';

import DesktopLayoutItem from '../../layout/renderer/layout-item/desktop';

const ReccomendedItem = ({ item, index = 0, layout = 'grid' }) => (
  <div className="ui-search-layout--grid item-search">
    <div className="ui-search-layout__item">
      <DesktopLayoutItem
        item={item}
        index={index}
        layout={layout}
        onMount={() => {}}
        options={{ lowEnd: false, preload: false, verboseLabel: false, renderVariationPicker: false }}
      />
    </div>
  </div>
);

export default ReccomendedItem;

ReccomendedItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.shape({}),
  layout: PropTypes.string,
};

/**
 * Module dependencies
 */
import React from 'react';

import { LazyHydrate as Hydrator } from 'nordic/lazy';
import CategoriesHeader from '../../../../../components/header/categories/categories-header';
import CategoryTrendDesktopFactory from '../../../../../components/category-trends-carousel/chunk-category-trend-desktop-factory';
import SideBar from '../../../../../components/sidebar/sidebar.desktop';
import { SVG_SPRITES } from '../main/constants';
import Category from './main.cat';
import ChunkExhibitor from '../../../../../components/header/exhibitor/chunk-exhibitor-header.desktop.pi';
import SeoBlogsComponent from '../../../../../components/seo-blog-component';

/**
 * View Component
 */
const SearchDesktopCAT = (props) => {
  const namespace = 'ui-category-trends-desktop';

  return (
    <Category {...props}>
      <Hydrator ssrOnly>
        <ChunkExhibitor />
      </Hydrator>
      <Hydrator ssrOnly>
        <CategoriesHeader />
      </Hydrator>
      <Hydrator ssrOnly>{SVG_SPRITES}</Hydrator>
      <section className={`${namespace}-content`}>
        <div className={`${namespace}-content-main`}>
          <SideBar />
          <section className={`${namespace}-content-main-components`}>
            <CategoryTrendDesktopFactory />
          </section>
        </div>
      </section>
      <SeoBlogsComponent />
    </Category>
  );
};

/**
 * Inject i18n context as props into View.
 */
export default SearchDesktopCAT;

import { useSearch } from '../../../hooks/context';

const useExhibitorHeader = () => {
  const { exhibitor } = useSearch();

  if (!exhibitor?.parameters) {
    return null;
  }

  const srcImage = exhibitor?.parameters?.background_image;
  const linkImage = exhibitor?.parameters?.permalink;

  return { srcImage, linkImage };
};

export default useExhibitorHeader;
